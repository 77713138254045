<script>
    export let formAction;
    export let langMessages;

    let searchForm;
    let isFormShow = false;

    const header = document.querySelector('.header');
    const menuToggleButton = document.querySelector('.header-navigation-toggle');

    const formShow = () => {
        isFormShow = true;
        if (header.classList.contains('mobile-menu--active')) {
            header.classList.remove('mobile-menu--active');
        }

        if (menuToggleButton.classList.contains('header-navigation-toggle--active')) {
            menuToggleButton.classList.remove('header-navigation-toggle--active');
        }

        header.before(searchForm);
    }
</script>

<div class="header-search-form">
    <button
            class="header-search-form__toggle"
            type="button"
            on:click={formShow}
    >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 9.12992C0.000352687 7.68397 0.344127 6.25878 1.00302 4.97168C1.66191 3.68458 2.61708 2.57238 3.7899 1.72663C4.96271 0.880889 6.31962 0.325792 7.74893 0.107042C9.17824 -0.111709 10.6391 0.0121412 12.0111 0.468397C13.3832 0.924653 14.6273 1.70027 15.641 2.73138C16.6547 3.7625 17.409 5.01964 17.8418 6.39929C18.2746 7.77895 18.3735 9.24167 18.1304 10.667C17.8874 12.0924 17.3092 13.4397 16.4436 14.5979L19.623 17.7729C19.868 18.0182 20.0055 18.3507 20.0053 18.6975C20.0051 19.0442 19.8672 19.3766 19.6219 19.6216C19.3766 19.8666 19.044 20.0041 18.6973 20.0039C18.3506 20.0037 18.0181 19.8658 17.7731 19.6205L14.5982 16.4455C13.2407 17.4597 11.6278 18.0762 9.93992 18.226C8.25205 18.3759 6.55579 18.0531 5.04087 17.294C3.52595 16.5348 2.25212 15.3691 1.36188 13.9273C0.471642 12.4855 9.85708e-05 10.8244 0 9.12992ZM9.13019 2.60804C7.84038 2.60848 6.57966 2.99135 5.50743 3.70825C4.4352 4.42515 3.59962 5.44388 3.10633 6.63563C2.61305 7.82739 2.4842 9.13865 2.7361 10.4036C2.988 11.6686 3.60932 12.8305 4.52151 13.7424C5.4337 14.6543 6.59579 15.2752 7.86085 15.5267C9.12591 15.7781 10.4371 15.6488 11.6287 15.1551C12.8203 14.6615 13.8388 13.8255 14.5553 12.7531C15.2718 11.6806 15.6543 10.4197 15.6543 9.12992C15.6543 8.27327 15.4855 7.42501 15.1576 6.6336C14.8297 5.84218 14.3491 5.12312 13.7433 4.51748C13.1374 3.91183 12.4182 3.43148 11.6267 3.10386C10.8352 2.77623 9.98685 2.60775 9.13019 2.60804Z"
                  fill="#B6B6B6"/>
        </svg>

        <span>{langMessages.inputPlaceholder}</span>
    </button>

    <form
            class="header-search-form__form"
            class:header-search-form__form--active={isFormShow}
            action="{formAction}"
            bind:this={searchForm}
    >
        <div class="header-search-form__input-group">
            <div class="header-search-form__icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" width="25" height="25">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                </svg>
            </div>

            <input type="text" name="q" value="" placeholder="{langMessages.inputPlaceholder}">

            <button
                    class="header-search-form__button-close"
                    type="button"
                    title="{langMessages.modalClose}"
                    on:click={() => {isFormShow = false}}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" width="24" height="24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
    </form>
</div>