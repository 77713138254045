import App from './App.svelte';

const headerSearchFormApp = new App({
	target: document.getElementById('headerSearchForm'),
});

export default headerSearchFormApp;

const event = new CustomEvent('headerSearchFormAppReady', {detail: headerSearchFormApp});

document.dispatchEvent(event);

